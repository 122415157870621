export const UserRole = {
  SUPER: 'SUPER',
  Admin: 'administrador',
  Editor: 'ROL_USER',
  Natural: 'Natural',
  Juridica: 'Juridica',
  Contador: 'Contador',
  Contable: 'Contable',
  Persona: 'ROL_PERSONA',
  SinMembresia: 'Usuario sin membresia',
  auxiliar: 'auxiliar',
};

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'es';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

export const adminRoot = '/app';
export const VencimientoToken = '/app/login';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/9.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin,
};

export const themeColorStorageKey = 'yellowgranola';
export const isMultiColorActive = true;
export const defaultColor = 'light.yellowgranola';
export const isDarkSwitchActive = false;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

// AMBIENTE LOCAL
// export const urlBase = 'http://localhost:5000';
// export const urlBaseReact = 'http://localhost:3000';
// AMBIENTE PRODUCCION
export const urlBase = 'https://app.calendartax.com:5000';
export const urlBaseReact = 'https://calendartax.com';
// AMBIENTE PRUEBASS
// export const urlBase = 'http://18.234.253.64:5000';
// export const urlBaseReact =
// 'http://www.calendartax.ml.s3-website-us-east-1.amazonaws.com';

export const idPerfil = {
  Persona: '6248a0ad7db77f2667375bed',
  Contador: '6248a0907db77f2667375be6',
  SinMembresia: '62c83054dd1ca737a7de3c52',
  Administrador: '62354f83cad277b637c51aa5',
};

export const diasSemana = [
  {
    _id: 'lunes',
    dia: 'lunes',
  },
  { _id: 'martes', dia: 'martes' },
  { _id: 'miercoles', dia: 'miércoles' },
  { _id: 'jueves', dia: 'jueves' },
  { _id: 'viernes', dia: 'viernes' },
  { _id: 'sabado', dia: 'sábado' },
  { _id: 'domingo', dia: 'domingo' },
];

export const estado = {
  APROBADO: 'Aprobado',
  PENDIENTE: 'Pendiente',
  RECHAZADO: 'Rechazado',
};

export const optionsDeDias = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
  { value: 13, label: '13' },
  { value: 14, label: '14' },
  { value: 15, label: '15' },
];

export const registro = 'registro';
export const Formulariocliente = 'cliente';
export const FormularioUsuario = 'usuario';

export const Estatuto = '62c82d70dd1ca737a7de3c4b';
