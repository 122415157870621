/* eslint-disable */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth } from 'helpers/Firebase';
import { adminRoot, currentUser } from 'constants/defaultValues';
import { setCurrentUser } from 'helpers/Utils';
import jwt_decode from 'jwt-decode';
import {
  LOGIN_USER_REQUEST,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD_REQUEST,
  RESET_PASSWORD_REQUEST,
} from '../contants';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  userTokenRefreshRequest,
  userTokenRefreshSuccess,
  userTokenRefreshFailure,
} from './actions';

import login, { obtnerModulosMenus } from '../../services/login';

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER_REQUEST, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) => {
  const body = {
    username: email,
    password,
  };

  return login(body)
    .then((user) => user)
    .catch((error) => error.response.data);
};

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;

  const loginUser = yield call(loginWithEmailPasswordAsync, email, password);

  if (!loginUser.message) {
    console.log('este es el item', loginUser.data);

    console.log(loginUser);
    const item = {
      id: loginUser.data.id,
      idPerfil: loginUser.data.id,
      activo: loginUser.data.activado,
      token: loginUser.data.token,
      role: loginUser.data.rol,
      email: loginUser.data.username,
    };
    console.log(item.token);
    const headers = {
      Authorization: `Bearer ${item.token}`,
    };
    obtnerModulosMenus(item.idPerfil, headers).then((response) => {
      item.modulo = response.data;
      setCurrentUser(item);
      history.push(adminRoot);
      window.location.reload(true);
    });
    yield put(loginUserSuccess(item));
  } else {
    yield put(loginUserError(loginUser.message));
  }

  // } catch (error) {
  //   yield put(loginUserError(error));
  // }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error);

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      const item = { uid: registerUser.user.uid, ...currentUser };
      console.log(item);
      setCurrentUser(item);
      yield put(registerUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  await auth
    .signOut()
    .then((user) => user)
    .catch((error) => error);
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD_REQUEST, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  return await auth
    .sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD_REQUEST, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

const checkTokenExpirationMiddleware = (store) => (next) => (action) => {
  const token =
    JSON.parse(localStorage.getItem('user')) &&
    JSON.parse(localStorage.getItem('user')).token;
  if (jwtDecode(token).exp < Date.now() / 1000) {
    next(action);
    localStorage.clear();
  }
  next(action);
};

function* loadInitialActions() {
  const dateNow = new Date();
  console.log('Que cosas tan tremendas');
  console.log(
    jwt_decode(token).exp < dateNow.getTime() - jwt_decode(token).iat
  );
  const token =
    JSON.parse(localStorage.getItem('user')) &&
    JSON.parse(localStorage.getItem('user')).token;
  if (
    token &&
    jwt_decode(token).exp < dateNow.getTime() - jwt_decode(token).iat
  ) {
    logout();
  }
}

export function* watchTokenUser() {
  yield takeEvery(monitorableAction, monitor);
}

// Intento de renovación de Token

const ignoreActionTypes = ['TOKEN_REFRESH'];

function monitorableAction(action) {
  return (
    action.type.includes('REQUEST') &&
    ignoreActionTypes.every((fragment) => !action.type.includes(fragment))
  );
}

function identifyAction(action) {
  return action.type.split('_').slice(0, -1).join('_');
}
function getSuccessType(action) {
  return `${identifyAction(action)}_SUCCESS`;
}
function getFailType(action) {
  return `${identifyAction(action)}_FAILURE`;
}

function* monitor(monitoredAction) {
  console.log('started monitoring', monitoredAction.type);
  const { fail } = yield race({
    success: take(getSuccessType(monitoredAction)),
    fail: take(getFailType(monitoredAction)),
  });

  if (fail && fail.payload && fail.payload.code === 401) {
    console.log('detected 401, refreshing token');
    yield put(userTokenRefreshRequest());

    const { success } = yield race({
      success: take(userTokenRefreshSuccess().type),
      fail: take(userTokenRefreshFailure().type),
    });

    if (success) {
      console.log('token refreshed, retrying', monitoredAction.type);
      yield put(monitoredAction);
    } else {
      console.log('token refresh failed, logging out user');
      yield put(userLogoutRequest());
    }
  }

  console.log('monitoring', monitoredAction.type, 'finished');
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    // fork(watchTokenUser),
  ]);
}
