import axios from 'axios';
// import { encode } from 'base-64';
import { urlBase } from '../constants/defaultValues';

const baseUrl = urlBase;

const login = (body) => {
  return axios.post(`${baseUrl}/auth/login`, body);
};

export const obtnerModulosMenus = (id, headers) =>
  axios.get(`${baseUrl}/auth/modulos_menus/${id}`, { headers });

export default login;
